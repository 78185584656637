import axios from 'axios';
import {useToast} from "vue-toastification";
import store from '@/store';
import i18n from '@/i18n';
import router from "@/router";

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});

export const assets = axios.create({
    baseURL: process.env.VUE_APP_ASSETS_BASE_URL
});

const {t} = i18n.global;

api.interceptors.response.use(async function (response) {
    return response;
}, async function (error) {
    const {status, config, data} = error.response;

    if (status === 401) {
        if (store.getters.authenticated) {
            if (config.url.toLowerCase().indexOf('login') !== -1) {
                useToast().error(t('anonymous.login.form.errors.wrong-auth'));
            } else {
                useToast().warning(t('anonymous.login.form.errors.session-expired'));
            }
            await router.push({name: 'login'});
            store.commit('logout');
        } else {
            useToast().error('Incorrect username or password.');
        }
    } else if (status === 403) {
        useToast().error(t('anonymous.login.form.errors.not-authorized-action'));
    } else if (status === 400 && data.violations && data.violations[0] === 'unknown') {
        useToast().error(t('general.violations.unknown'));
    }

    return Promise.resolve();
});
import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";

const routes = [{
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: {path: '/'}
}, {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import("@/views/OnboardingView")
}, {
    path: '/login',
    redirect: {name: 'login'}
}, {
    path: '/invitation',
    redirect: {name: 'accept-invitation'}
}, {
    path: '/invitation/:code',
    redirect: {name: 'accept-invitation'}
}, {
    path: '/password',
    redirect: {name: 'reset-password'}
}, {
    path: '/password/:code',
    redirect: {name: 'set-password'}
}, {
    path: '/account',
    component: () => import ("@/views/AnonymousView"),
    children: [{
        path: 'password',
        name: 'reset-password',
        component: () => import ("@/views/AnonymousPasswordForgotView")
    }, {
        path: 'password/:code',
        name: 'set-password',
        alias: '/password/:code',
        component: () => import ("@/views/AnonymousPasswordSetView")

    }, {
        path: 'invitation/:code?',
        name: 'accept-invitation',
        component: () => import ("@/views/AnonymousInvitationAcceptView")
    }, {
        path: 'login',
        name: "login",
        component: () => import("@/views/AnonymousLoginView")
    }]
}, {
    path: '/',
    redirect: {name: 'dashboard'},
    component: () => import ("@/views/AdminView"),
    children: [{
        path: 'dashboard',
        name: 'dashboard',
        component: () => import ("@/views/DashboardView")
    }, {
        path: 'sites',
        component: () => import ("@/views/SiteListView")
    }, {
        path: 'sites/create',
        component: () => import ("@/views/SiteCreateView")
    }, {
        path: '/sites/:siteId',
        component: () => import ("@/views/SiteUpdateView")
    }, {
        path: '/sites/:siteId/units/create',
        component: () => import ("@/views/UnitCreateView")
    }, {
        path: '/sites/:siteId/units/:unitId',
        component: () => import ("@/views/UnitUpdateView")
    }, {
        path: '/lock-unit/create',
        component: () => import ("@/views/JobsLockUnitCreateView.vue")
    }, {
        path: 'users',
        component: () => import ("@/views/UserListView")
    }, {
        path: 'users/invite',
        component: () => import ("@/views/InvitationCreateView")
    }, {
        path: 'users/:userId',
        component: () => import ("@/views/UserUpdateView")
    }, {
        path: 'lock-unit',
        component: () => import ("@/views/JobsLockUnitView.vue")
    }, {
        path: 'share-access',
        component: () => import ("@/views/JobsShareAccessView.vue")
    }, {
        path: 'share-access/create',
        component: () => import ("@/views/JobsShareAccessCreateView.vue")
    }, {
        path: 'padlocks',
        component: () => import ("@/views/PadlocksView")
    }, {
        path: 'emails',
        component: () => import ("@/views/EmailsView.vue")
    }, {
        path: 'languages',
        component: () => import ("@/views/LanguagesView.vue")
    }, {
        path: 'connect',
        name: 'connect',
        component: () => import ("@/views/ConnectView.vue")
    }, {
        path: 'connect/storeganise',
        component: () => import ("@/views/ConnectStoreganiseView")
    }, {
        path: 'connect/store365',
        component: () => import ("@/views/ConnectStore365View")
    }, {
        path: 'connect/storman',
        component: () => import ("@/views/ConnectStormanView")
    }, {
        path: 'connect/api_key_user',
        alias: ['connect/space_manager', 'connect/stor_app', 'connect/stora', 'connect/custom'],
        component: () => import ("@/views/ConnectApiKeyUserView.vue")
    }, {
        path: 'settings',
        component: () => import ("@/views/SettingsView.vue")
    }]
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name === 'login' || to.name === 'onboarding' || to.path.startsWith("/account")) {
        next();
    } else if (!store.getters.authenticated) {
        next({name: 'login'});
    } else if (to.path.indexOf("connect/") !== -1 && store.getters.integration.type !== 'none') {
        next({name: 'connect'});
    } else {
        next();
    }
})

export default router;
import '../styles/customTheme.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "vue-toastification/dist/index.css";
import {createApp} from 'vue';
import Toast from "vue-toastification";
import store from './store';
import router from './router';
import i18n from "@/i18n";
import App from "@/App";

const app = createApp(App)
    .use(Toast)
    .use(i18n)
    .use(router)
    .use(store);

router.isReady().then(() => {
    app.mount('#app');
});
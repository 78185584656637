import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist'
import {api, assets} from "@/api";
import {useToast} from "vue-toastification";
import i18n from "@/i18n";
import router from "@/router";

const {t} = i18n.global;

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = createStore({
    plugins: [vuexLocal.plugin],
    state() {
        return {
            legal: {
                terms: "",
                privacyPolicy: ""
            },
            users: [],
            invitations: [],
            sites: [],
            collectJobs: [],
            lockJobs: [],
            padlocks: [],
            apiKeys: [],
            units: new Map(),
            user: null,
            token: null,
            stormanClientId: null,
            stormanClientSecret: null,
            stormanSubdomain: null,
            settings: {
                languages: {
                    default: 'en',
                    supported: ['en']
                },
                integration: {}
            },
            emails: null
        }
    },
    getters: {
        terms: state => state.legal.terms,
        privacyPolicy: state => state.legal.privacyPolicy,
        authenticated: state => {
            const tokenPresent = (typeof state.token !== 'undefined') && state.token != null;

            if (tokenPresent && !api.defaults.headers['Authorization']) {
                api.defaults.headers['Authorization'] = `Bearer ${state.token}`;
            }

            return tokenPresent;
        },
        token: (state, getters) => getters.authenticated ? null : state.token,
        user: (state, getters) => getters.authenticated ? null : state.user,
        users: state => state.users ?? [],
        padlocks: state => state.padlocks ?? [],
        apiKeys: state => state.apiKeys,
        invitations: state => state.invitations,
        sites: state => state.sites ?? [],
        emails: state => state.emails,
        site: (state) => (siteId) => state.sites.find(s => s.id === siteId) ?? null,
        units: (state) => (siteId) => state.units[siteId] ?? [],
        unitsLockable: (state) => (siteId) =>
            siteId == null
                ? []
                : (state.units[siteId] ?? [])
                    .filter(u => u.integrationRubikEnabled)
                    .filter(unit => !state.lockJobs.find(job => job.unitId === unit.id))
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }),
        unitsCollectable: (state) => (siteId) =>
            siteId == null
                ? []
                : (state.units[siteId] ?? [])
                    .filter(u => u.locked),
        unit: (state) => (siteId, unitId) => state.units[siteId]?.find(u => u.id === unitId) ?? null,
        collectJobs: state => state.collectJobs ?? [],
        lockJobs: state => state.lockJobs ?? [],
        settings: state => state.settings,
        stormanSubdomain: state => state.stormanSubdomain,
        integration: state => state.settings.integration,
        isIntegrated: state => state.settings.integration.type !== 'none',
        // TODO fix user vs self - both necessary?
        self: state => state.settings.user,
        productLinks: () => {
            return {
                adminLinkUrl: process.env.VUE_APP_ADMIN_LINK_URL,
                adminLinkLabel: process.env.VUE_APP_ADMIN_LINK_LABEL,
                appLinkUrl: process.env.VUE_APP_APP_LINK_URL,
                appLinkLabel: process.env.VUE_APP_APP_LINK_LABEL,
                websiteLinkUrl: process.env.VUE_APP_WEBSITE_LINK_URL,
                websiteLinkLabel: process.env.VUE_APP_WEBSITE_LINK_LABEL,
                supportEmailUrl: process.env.VUE_APP_SUPPORT_EMAIL_URL,
                supportEmailLabel: process.env.VUE_APP_SUPPORT_EMAIL_LABEL,
                contactFormLinkUrl: process.env.VUE_APP_CONTACT_FORM_LINK_URL,
                contactFormLinkLabel: process.env.VUE_APP_CONTACT_FORM_LINK_LABEL
            }
        }
    },
    mutations: {
        terms: (state, terms) => state.legal.terms = terms,
        privacyPolicy: (state, privacyPolicy) => state.legal.privacyPolicy = privacyPolicy,
        reset(state) {
            state.user = null;
            state.token = null;
        },
        async logout(state) {
            state.user = null;
            state.token = null;

            delete api.defaults.headers['Authorization'];

            // eslint-disable-next-line
            Beacon('logout')
            localStorage.clear();

            await router.push('/login')
            await router.go(0);
        },
        session(state, {user, token}) {
            state.user = user;
            state.token = token;

            api.defaults.headers['Authorization'] = `Bearer ${token}`;
        },
        units: (state, {siteId, units}) => state.units[siteId] = units,
        sites: (state, sites) => state.sites = sites,
        emails: (state, emails) => state.emails = emails,
        padlocks: (state, padlocks) => state.padlocks = padlocks,
        apiKeys: (state, apiKeys) => state.apiKeys = apiKeys,
        users: (state, users) => state.users = users,
        stormanClient: (state, {id, secret, subdomain}) => {
            state.stormanClientId = id;
            state.stormanClientSecret = secret;
            state.stormanSubdomain = subdomain;
        },
        collectJobs: (state, collectJobs) => state.collectJobs = collectJobs,
        lockJobs: (state, lockJobs) => state.lockJobs = lockJobs,
        invitations: (state, invitations) => state.invitations = invitations,
        settings: (state, settings) => state.settings = settings
    },
    actions: {
        async register(context, registration) {
            await api.post('/account/register', registration);
            // TODO log user in and forward them
        },
        async login(context, {email, password}) {
            context.commit('reset');

            const response = await api.post('/auth/login', {
                email: email,
                password: password
            });

            if (!response) {
                return;
            }

            const {user, token} = response.data;

            // eslint-disable-next-line
            Beacon('identify', {
                name: user.name,
                email: user.email,
            });

            context.commit('session', {user, token});
            await store.dispatch('loadAll');
        },
        async createInvitation(context, invitation) {
            try {
                await api.post('/invitations', invitation);
                await context.dispatch('loadInvitations');
                useToast().success(t('users.create.form.success', {email: invitation.email}));
            } catch ({response}) {
                useToast().error(t(`users.create.form.errors.${response.data.violations[0]}`, {email: invitation.email}));
            }
        },
        async deleteInvitation(context, {invitationId, email}) {
            await api.delete(`/invitations/${invitationId}`);
            useToast().success(t('invitations.delete.form.success', {email}));
            await context.dispatch('loadInvitations');
        },
        async loadInvitation(context, code) {
            return (await api.get(`/account/invitation/${code}`)).data;
        },
        async acceptInvitation(context, payload) {
            const response = await api.post(`/account/invitation/${payload.code}`, payload);
            useToast().success(t('anonymous.invitation.success-info'));
            context.commit('session', {user: response.data.user, token: response.data.token});
            await context.dispatch('loadSettings');
        },
        async resetPassword(context, email) {
            await api.post(`/account/password/reset`, {email});
            useToast().success(t('anonymous.forgot-password.success'));
        },
        async loadPasswordResetRequest(context, code) {
            return (await api.get(`/account/password/set/${code}`)).data;
        },
        async setPassword(context, {code, password}) {
            const response = await api.post(`/account/password/set/${code}`, {password});
            useToast().success(t('anonymous.set-password.success-info'));
            context.commit('session', {user: response.data.user, token: response.data.token});
            await router.push("/");
        },
        async updateUser(context, payload) {
            try {
                await api.put(`/users/${payload.userId}`, payload);
                useToast().success(t('users.update.form.success', {name: payload.name}));
                await context.dispatch('loadUsers');
            } catch ({response}) {
                useToast().error(t('users.update.form.error', {name: payload.name}));
            }
        },
        async loadAssets(context) {
            context.commit('terms', (await assets.get("/terms.html")).data);
            context.commit('privacyPolicy', (await assets.get("/privacy-policy.html")).data);
        },
        loadSites: async (context) => context.commit('sites', (await api.get('/sites')).data),
        createSite: async (context, payload) => {
            try {
                const site = (await api.put('/sites', payload)).data;
                useToast().success(t("sites.create.form.success", {site: payload.name}));
                await context.dispatch("loadSites");
                return site;
            } catch ({response}) {
                useToast().error(t("sites.create.form.error", {site: payload.name}));
            }
        },
        updateSite: async (context, payload) => {
            try {
                await api.put(`/sites/${payload.siteId}`, payload);
                useToast().success(t("sites.update.form.success", {site: payload.name}));
                await context.dispatch('loadSites');
            } catch ({response}) {
                useToast().error(t("sites.update.form.error", {site: payload.name}));
            }
        },
        createUnit: async (context, payload) => {
            try {
                const unit = (await api.put('/units', payload)).data;
                useToast().success(t('units.create.form.success', {unit: payload.name}));
                return unit;
            } catch ({response}) {
                useToast().error(t('units.create.form.error', {unit: payload.name}));
            }
        },
        updateUnit: async (context, payload) => {
            try {
                const unit = (await api.put(`/units/${payload.unitId}`, payload)).data;
                useToast().success(t('units.update.form.success', {unit: payload.name}));
                return unit;
            } catch ({response}) {
                useToast().error(t('units.update.form.error', {unit: payload.name}));
            }
        },
        loadPadlocks: async (context) => context.commit('padlocks', (await api.get('/padlocks')).data),
        loadUsers: async (context) => context.commit('users', (await api.get('/users')).data),
        loadJobs: async (context) => {
            await context.dispatch('loadLockUnitJobs');
            await context.dispatch('loadCollectPadlockJobs');
        },
        createLockUnitJob: async (context, payload) => {
            await api.post('/lockUnitJobs', payload);
            await useToast().success('Successfully created lock unit job.');
            await context.dispatch('loadLockUnitJobs');
        },
        loadCollectPadlockJobs: async (context) => context.commit('collectJobs', (await api.get('/collectPadlockJobs?state=executable_anytime')).data),
        createCollectPadlockJob: async (context, payload) => {
            try {
                await api.post(`/collectPadlockJobs`, payload);
                await useToast().success(t('padlocks.collect.form.success'));
            } catch ({response}) {
                await useToast().error(t('padlocks.collect.form.error'));
            }
        },
        deleteCollectPadlockJob: async (context, {id}) => {
            await api.delete(`/collectPadlockJobs/${id}`);
            await useToast().success('Successfully deleted job.');
            await context.dispatch("loadCollectPadlockJobs");
        },
        loadLockUnitJobs: async (context) => context.commit('lockJobs', (await api.get('/lockUnitJobs?state=executable_anytime,executable_urgent,waiting')).data),
        deleteLockUnitJob: async (context, {id}) => {
            await api.delete(`/lockUnitJobs/${id}`);
            useToast().success('Successfully deleted job.');
            await context.dispatch("loadLockUnitJobs");
        },
        loadInvitations: async (context) => context.commit('invitations', (await api.get('/invitations')).data),
        loadEmails: async (context) => context.commit('emails', (await api.get('/emails')).data),
        loadSettings: async (context) => context.commit('settings', (await api.get('/settings')).data),
        async updateLanguagePreferences(context, payload) {
            try {
                for (let i = 0; i < payload.add.length; i++) {
                    await api.post(`/settings/languages/${payload.add[i]}`)
                }

                for (let j = 0; j < payload.remove.length; j++) {
                    await api.delete(`/settings/languages/${payload.remove[j]}`)
                }

                if (payload.default) {
                    await api.put(`/settings/languages/${payload.default}/default`)
                }

                await context.dispatch("loadSettings");

                await useToast().success('Successfully updated language preferences.');
            } catch ({response}) {
                await useToast().error('Failed to update language preferences.');
            }
        },
        async updateMessages(context, payload) {
            try {
                for (let i = 0; i < payload.length; i++) {
                    await api.put('/emails', payload[i]);
                }

                await context.dispatch("loadEmails");
                await useToast().success('Successfully updated message templates.');
            } catch ({response}) {
                await useToast().error('Failed to update language preferences.');
            }
        },
        async connectStoreganise(context, {businessCode, apiKey}) {
            await api.post('/integration/storeganise', {businessCode, apiKey});
            await context.dispatch("loadSites");
            await context.dispatch("loadSettings");
        },
        async connectStore365(context, {clientId, username, password}) {
            await api.post('/integration/store365', {clientId, username, password});
            await context.dispatch("loadSites");
            await context.dispatch("loadSettings");
        },
        async connectStorman(context, {code, secretKey}) {
            await api.post('/integration/storman', {
                code,
                secretKey,
                clientId: context.state.stormanClientId,
                clientSecret: context.state.stormanClientSecret,
                subdomain: context.state.stormanSubdomain
            });
            await context.dispatch("loadSites");
            await context.dispatch("loadSettings");
        },
        async connectApiUser(context, integrationType) {
            await api.post('/integration/api_key', {integrationType});
            await context.dispatch("loadSettings");
        },
        async manualSetupDone(context, payload) {
            await api.put('/integration/manual', payload);
            await context.dispatch("loadSettings");
            if (payload.done) {
                await useToast().success('Manual setup marked as finished.');
            } else {
                await useToast().warning('Manual setup marked as unfinished.');
            }
        },
        async disconnectIntegration(context) {
            await api.delete('/integration');
            await context.dispatch("loadSettings");

            await useToast().success('Successfully disconnected management software.');
        },
        async loadUnits(context) {
            await context.dispatch("loadSites");
            for (let i = 0; i < context.state.sites.length; i++) {
                const siteId = context.state.sites[i].id;

                const units = (await api.get(`/units?siteId=${siteId}`)).data;
                context.commit('units', {siteId, units})
            }
        },
        async loadAll(context) {
            await context.dispatch("loadSettings");
            await context.dispatch("loadInvitations");
            await context.dispatch("loadUsers");
            await context.dispatch("loadJobs");
            await context.dispatch("loadPadlocks");
        }
    }
})

export default store

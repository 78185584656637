import {createI18n} from 'vue-i18n'
import labelsEn from './labels-en.json'

const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: labelsEn
    },
    warnHtmlInMessage: 'off'
});

export default i18n;